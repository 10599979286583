<template>
  <!-- 收支明细 -->
  <el-card class="box-card income">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">收支明细</h3>
    </div>
    <div class="box">
      <div class="top">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="门店名称">
            <el-input
              placeholder="请输入门店名称"
              size="mini"
              v-model="form.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店电话">
            <el-input
              placeholder="请输入门店电话"
              size="mini"
              v-model="form.phone"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店ID">
            <el-input
              placeholder="请输入门店ID"
              size="mini"
              v-model="form.store_id"
            ></el-input>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              v-model="TimeValue"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              size="mini"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-button size="mini" type="primary" @click="search">筛选</el-button>
        <el-button size="mini" type="primary" @click="exportFile">导出</el-button>
      </div>
      <el-table height="570" border size="mini" :data="list" style="width: 100%">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="store_id" label="门店ID"> </el-table-column>
        <el-table-column prop="store_name" label="门店名称"> </el-table-column>
        <el-table-column prop="store_phone" label="门店电话"> </el-table-column>
        <el-table-column prop="store_address" label="门店地址">
        </el-table-column>
        <el-table-column prop="pay_price" label="日收入"> </el-table-column>
        <el-table-column prop="refund_price" label="日退款"> </el-table-column>
        <el-table-column prop="net_income" label="日净收入"> </el-table-column>
        <el-table-column prop="day_time" label="日期"> </el-table-column>
        <el-table-column prop="createtime" label="创建时间"> </el-table-column>
        <el-table-column width="180" label="收支明细">
          <template slot-scope="scope">
            <el-button
              type="primary"
              round
              class="shou"
              size="mini"
              @click="lookDetail(scope.row.id)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
       <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportShop2">确 定</el-button>
      </span>
    </el-dialog>
      <el-pagination
        @current-change="CurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 收支明细弹窗 -->
    <el-dialog title="收支明细列表" :visible.sync="withdrawDetailShow" width="80">
      <div style="position: absolute; right: 90px; top: 16px; display: flex; height: 80px; align-items: center;">
        <div class="addshop shou"
              style="
               background-color: rgb(252, 85, 49);
                padding: 5px 10px;
                color: rgb(255, 255, 255);
                border-radius: 4px;
                width: 105px;
                margin-right: 20px
              "
              @click="detailexport"
              >导出订单 <i class="el-icon-folder-opened"></i> </div>
              
             <el-radio v-model="detailFrom.export_type" :label="1">当前页</el-radio>
            <el-radio v-model="detailFrom.export_type" :label="2">导出所有</el-radio>
      </div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="下单列表" name="first">
          <el-table
            :data="withdrawDetailList"
            stripe
            border
            :header-row-style="{ height: '60px' }"
            :row-style="{ height: '30px' }"
          >
        <el-table-column prop="order_no" label="订单编号">
        </el-table-column>
        <el-table-column prop="name" label="收货人名称"> </el-table-column>
        <el-table-column prop="mobile" label="收货人电话">
        </el-table-column>
        <el-table-column prop="address" label="收货人地址">
        </el-table-column>
        <el-table-column prop="type_name" label="订单分类">
        </el-table-column>
        <el-table-column prop="total_price" label="订单总价">
        </el-table-column>
        <el-table-column label="订单完成时间">
           <template slot-scope="scope">
             <div v-if="scope.row.confirm_time">
              {{ scope.row.confirm_time | datefilter }}
             </div>
          </template>
        </el-table-column>
        <el-table-column prop="pay_price" label="支付金额">
        </el-table-column>
        <el-table-column label="订单类型">
          <template slot-scope="scope">
            {{ scope.row.is_offline == 1 ? "自提订单" : "物流订单" }}
          </template>
        </el-table-column>
        <el-table-column prop="express_no" label="快递单号">
        </el-table-column>
        <el-table-column prop="express" label="快递公司">
        </el-table-column>
        <el-table-column prop="store_name" label="所属门店">
        </el-table-column>
      </el-table>
        </el-tab-pane>
        <el-tab-pane label="退款列表" name="second">
          <el-table
          :data="withdrawDetailList"
          stripe
          border
          :header-row-style="{ height: '60px' }"
          :row-style="{ height: '30px' }"
        >
          <!-- <el-table-column label="ID" prop="id"> </el-table-column> -->
          <el-table-column prop="order_no" label="订单编号">
           
          </el-table-column>
          <el-table-column label="退款编号" prop="order_refund_no">
          </el-table-column>
          <el-table-column prop="store_name" label="所属门店">
          </el-table-column>
          <el-table-column label="申请时间" prop="addtime">
            <div slot-scope="scope">
              {{ scope.row.addtime | datefilter }}
            </div>
          </el-table-column>
          <el-table-column label="退货金额" prop="refund_price">
          </el-table-column>
          <!-- <el-table-column label="售后类型" width="200">
            <el-tag size="mini" type="warning" effect="dark">退货</el-tag>
          </el-table-column> -->
          <!-- <el-table-column label="申请理由" prop="desc"></el-table-column> -->
          <el-table-column label="商家操作时间">
            <div slot-scope="scope">
              <span v-if="scope.row.response_time != 0">{{
                scope.row.response_time | datefilter
              }}</span>
              <span v-else>暂未处理</span>
            </div>
          </el-table-column>
          <!-- <el-table-column label="进度" prop="status">
            <div slot-scope="scope">
              <el-tag
                v-if="scope.row.status == 1"
                size="mini"
                type="success"
                effect="dark"
                >已处理</el-tag
              >
              <el-tag
                v-else-if="scope.row.status == 0"
                size="mini"
                type="info"
                effect="dark"
                >未处理</el-tag
              >
              <el-tag v-else size="mini" type="danger" effect="dark"
                >已拒绝</el-tag
              >
            </div>
          </el-table-column> -->
          </el-table>
        </el-tab-pane>
      </el-tabs>
      
      <el-pagination
        @current-change="withdrawDetailCrtChg"
        :current-page="withdrawDetailpage"
        layout="total, prev, pager, next, jumper"
        :total="withdrawDetailTotal"
      >
      </el-pagination>
    </el-dialog>
  </el-card>
</template>

<script>
import { store_capital_detail, setCapitalLogDetail, setCapitalLogDetailExport } from "@/api/api.js";
import { filenameFormat } from "@/utils/index";
export default {
  name: "income",
  components: {},
  data() {
    return {
      list: [],
      form: {
        name: "",
        phone: "",
        start_time: "",
        end_time: "",
        page: 1,
        export: "",
        export_type: "",
        store_id: "", //门店id
      },
      exportShow: false,
      exportType: 1,
      total: 0,
      TimeValue: null,
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "最近一周",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近一个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近三个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //   ],
      // },
      withdrawDetailId: '',
      withdrawDetailShow: false, //明细弹窗控制
      withdrawDetailList: [], //明细列表
      withdrawDetailpage: 1, //页码
      withdrawDetailTotal: 0, //总条数
      activeName:'first',
      detailFrom:{
        id: '',
        export: '',
        export_type: '',
        page: 1,
      }
    };
  },
  watch: {
    TimeValue: function (newVal, oldVal) {
      if (newVal != null) {
        this.form.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.form.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
    },
  },
  //计算属性 依赖缓存,多对一(即多个影响一个),不支持异步
  computed: {},
  //监控data中的数据变化,不依赖缓存,一对多,支持异步
  watch: {},
  //方法集合
  methods: {
    CurrentChange(e) {
      this.form.page = e
      this.getlist()
    },
    search() {
      if (this.TimeValue != null) {
        this.form.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.form.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
      this.form.page = 1
      this.getlist()
    },
    async getlist() {
        if (this.exportShow) {
        let { data, types, request } = await store_capital_detail(this.form, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        this.exportShow = false;
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
      } else {
        const { data } = await store_capital_detail(this.form);
        if (data.code != 200) return this.$message.error(data.data);
        console.log('data=========',data)
        this.list = data.data.data;
        this.total = data.data.total;
        console.log(this.list);
//         this.list.forEach((item) => {
//           item.pay_time = this.$timeTo.time3(item.pay_time);
//         });
      }
      // const res = await store_capital_detail(this.form);
      // this.list = res.data.data.data;
      // this.total = res.data.data.total
      this.form.export = "";
    },
    exportFile(){
      this.exportShow = true;
    },
     exportShows() {
      this.exportShow = false;
    },
     exportShop2() {
      this.form.export = "export";
      this.form.export_type = this.exportType;
      this.getlist();
    },
    // 门店提现明细,列表数据
    async lookDetail(id){
      this.detailFrom.export = ''
      this.detailFrom.export_type = ''
      this.detailFrom.id = id
      this.withdrawDetailShow = true
      this.withdrawDetailId = id
       const { data } = await setCapitalLogDetail({ id: this.withdrawDetailId, page: this.withdrawDetailpage });
      if (data.code != 200) return this.$message.error(data.data);
      if(this.activeName == 'first'){
        this.withdrawDetailList = data.data.order_detail.data
        this.withdrawDetailTotal = data.data.order_detail.total
      }else if(this.activeName == 'second'){
         this.withdrawDetailList = data.data.refund_detail.data
        this.withdrawDetailTotal = data.data.refund_detail.total
      }
      console.log('data======',data)
    },
     // 详情列表导出
    async detailexport(){
      if(!this.detailFrom.export_type){
       return this.$message.error('请选择导出范围');
      }
      if(this.activeName == 'first'){
         this.detailFrom.export = 'export'
      }else{
         this.detailFrom.export = 'refund_export'
      }
      let { data, types, request } = await setCapitalLogDetailExport(this.detailFrom, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
    },
    // 翻页
    withdrawDetailCrtChg(e){
      this.withdrawDetailpage = e
      this.lookDetail(this.withdrawDetailId)
    },
    // 弹窗列表切换
     handleClick() {
        this.lookDetail(this.withdrawDetailId)
      }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getlist();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.top {
  margin: 10px;
}
.el-form {
  display: flex;
}
</style>